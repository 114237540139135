import React, { useEffect } from 'react';
import './App.css';

import { useRef, useState } from 'react';
import './App.css';

import { KastWebSdkClient } from '@kalyzee/kast-web-sdk';
import { Whiteboard } from '@kalyzee/kast-web-sdk/adapted-web-components';
import { WhiteboardImperativeAPI } from '@kalyzee/kast-web-sdk/whiteboard';
import { Share } from './ShareOverlay';
import { ShareData } from './types';
import { FlexibleId, WebSocket } from '@kalyzee/kast-web-sdk/websocket';
import { addSharingIdToCurrentUrl, getParamFromCurrentUrl, removeSharingIdFromCurrentUrl } from './utils';

import { ReactComponent as LinkIcon } from './assets/svg/link.svg';
import { Error } from './Error';
import { ShareButton } from './ShareButton';
import { getTokens, removeTokens, setTokens } from './helpers/localStorage';
import { getSdkHttpInitOptions } from './helpers/http';

export const WhiteboardWrapper = () => {
  const [sdkRef] = useState(() => ({ 
    current: new KastWebSdkClient(
      {
        http: getSdkHttpInitOptions(),
      },
    ),
  }));
  const whiteboardRef = useRef<WhiteboardImperativeAPI>(null);
  const initRef = useRef(false);

  const [imageExportEnabled] = useState(getParamFromCurrentUrl('enable_image_export') !== 'false');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [displayShareInterface, setDisplaySharingInterface] = useState(false);
  const [shareData, setShareData] = useState<ShareData>();

  useEffect(() => {
    if (shareData) {
      addSharingIdToCurrentUrl(shareData.id);
    }
  }, [shareData]);

  const onError = (err: any) => {
    setError(err?.message ?? 'An error occured');
    setLoading(false);
    setShareData(undefined);
  } 

  const initWebsocket = async (): Promise<WebSocket | undefined> => {
    const wsClient = sdkRef.current.getWebsocketClient();

    if (!wsClient.connected && !initRef.current) {
      initRef.current = true;
      const tokens = getTokens();
      try {
        const authRes = await sdkRef.current.authenticateHttpClient({
          method: {
            type: tokens ? 'tokens' : 'guest',
            data: tokens ?? {
              username: 'User',
            },
          }
        });
        if (authRes) {
          setTokens(authRes);
        }
        await sdkRef.current.connectWebsocket();
      } catch(err: any) {
        if (err?.status === 403) {
          // Somehow the infinite guest token is not recognized anymore (maybe cleared from DB)
          removeTokens();
        }
        onError({ message: 'Could not connect.' });
        console.log('Error while initializing the websocket : ' + JSON.stringify(err));
        return;
      }
    }

    return wsClient;
  }

  const onShare = async () => {
    joinOrCreate(undefined, true);
  }

  const joinOrCreate = async (id?: string, sendCurrentContent=false) => {
    setLoading(true);
    const wsClient = await initWebsocket();
    if (wsClient) {
      if (wsClient.connected) {
        const _id = id ?? await createWhiteboard();
        if (_id) await joinWhiteboard(_id, sendCurrentContent);
        setLoading(false);
        return;
      }
    }
  }

  const onStopShare = async () => {
    whiteboardRef.current?.leave();
    setShareData(undefined);
    removeSharingIdFromCurrentUrl();
  }
  
  const joinWhiteboard = async (id: string, sendCurrentContent: boolean) => {
    console.log('Joining whiteboard')
    const whiteboard = whiteboardRef.current;
    if (!whiteboard) return;
    let flexibleId: FlexibleId = { type: 'short', value: id };
    // Check if is object id
    if (id.length === 24) flexibleId = { type: 'normal', value: id };

    const isInvisible = getParamFromCurrentUrl('invisible') === 'true';
    const joinRes = await whiteboard.join(flexibleId, { isInvisible }, !sendCurrentContent);
    if (joinRes?.error || !joinRes?.content) {
      onError(joinRes?.error);
      return;
    }

    if (joinRes.content) {
      setShareData({
        id: joinRes.content.shortId,
      });
    }

    if (sendCurrentContent) {
      // touch informs the whiteboard core that excalidraw has some
      // elements that need to be loaded into the current page
      whiteboard.touch();
      // syncing the elements to the server
      whiteboard.syncAll();
    }
  }

  const createWhiteboard = async (): Promise<string | null> => {
    const client = sdkRef.current.getWebsocketClient();
    if (!client) return null;

    const createRes = await client.emitter.whiteboard.create({});
    if (!createRes?.response || createRes?.error) {
      onError(createRes?.error);
      return null;
    }
    
    return createRes.response.shortId;
  }

  useEffect(() => {
    const urlId = getParamFromCurrentUrl('id');

    if (urlId) {
      initWebsocket();
      console.log('Connecting to websocket');
    }

    const listeners: any[] = [];

    const connectListener = sdkRef.current.addEventListener('websocket', 'connect', async (success) => {
      if (!success) return;

      let _id: string | undefined = undefined;
      if (shareData) {
        // reconnection
        _id = shareData.id;
      } else if (urlId) {
        // there is an id in the url
        _id = urlId;
      }

      // will either join WB or create a new one :
      await joinOrCreate(_id, !urlId);
    });
    listeners.push(connectListener);

    const disconnectListener = sdkRef.current.addEventListener('websocket', 'disconnect', () => {
      setLoading(true);
    });
    listeners.push(disconnectListener);

    const errorListener = sdkRef.current.addEventListener('websocket', 'error', (err) => {
      onError(err.message);
    })
    listeners.push(errorListener);

    return () => listeners.forEach(l => l.flush());
  }, []);

  const renderHelpHeader = (): JSX.Element => {
    return (
      <div>
        <div>App Version : {process.env.REACT_APP_VERSION}</div>
      </div>
    );
  };

  const renderShareButton = () => {
    if (displayShareInterface) return null;
    return (
      <div style={{
        position: 'absolute',
        bottom: '70px',
        left: '1rem',
        fontSize: '0.5em',
        zIndex: 50,
        opacity: 0.7,
      }}>
        <ShareButton 
          loading={loading} 
          shareData={shareData} 
          onClick={() => setDisplaySharingInterface(true)} 
        />
      </div>
    );
  }

  return (
    <div style={{
      position: 'relative',
      width: '100%',
      height: '100%',
    }}>
      <Whiteboard 
        ref={whiteboardRef} 
        renderHelpHeader={renderHelpHeader}
        sdk={sdkRef.current}
        customMenuButtons={[{
          content: (
            <div style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '0.5em',
              color: '#5b57d1'
            }}>
              <LinkIcon />
              Sharing options
            </div>
          ),
          onClick: () => setDisplaySharingInterface(true),
        }]} 
        imageExportEnabled={imageExportEnabled}
        onShare={() => setDisplaySharingInterface(true)}
      />
      <Share 
        loading={loading}
        shareData={shareData}
        onStopShare={onStopShare}
        onShare={onShare} 
        display={displayShareInterface}
        setDisplay={setDisplaySharingInterface}
      />
      <Error error={error} setError={setError} />
    </div>
  );
}

export default WhiteboardWrapper;